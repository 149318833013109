import React, { useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react';

// assets & components
import AboutECoachCard from 'components/pageECoach/aboutECoach/_index';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import ECoachSessionList from 'components/pageECoach/ECoachSessionList';
import ECoachSession from 'components/pageECoach/ECoachSession';
import FeedbackForm from 'components/pageECoach/FeedbackForm';
import Toolbar from 'components/pageECoach/Toolbar';

// context
import {
  ECoachSessionProvider,
  useSessionContext,
} from 'contexts/ECoachSessionContext';
import { LoadingStatus, useFeedbackConsumer } from 'hooks/useFeedbackConsumer';

function ECoach() {
  const { session, retrieveSession } = useSessionContext();
  const posthog = usePostHog();

  const [activePage, setActivePage] = useState('chat'); // chat, about, history
  const [chatGuideData, setChatGuideData] = useState({
    chatStartEnded: false,
    convoPrep: { with_who: '', goal: '', worried_by: '' },
    displayConvoPrep: false,
    displayPromptHelper: false,
    displaySampleCases: false,
    inProgress: false,
    promptHelper: { goal: '', theme: '' },
  });

  const { feedbackStatus, fetchFeedbackStatus, tryCreate } =
    useFeedbackConsumer();

  const resetChatGuideData = () => {
    const updates = {
      chatGuideClicked: false,
      chatStartEnded: false,
      convoPrep: { with_who: '', goal: '', worried_by: '' },
      displayConvoPrep: false,
      displayPromptHelper: false,
      displaySampleCases: false,
      inProgress: false,
      promptHelper: { goal: '', theme: '' },
    };
    setChatGuideData((prev) => ({ ...prev, ...updates }));
  };

  const handleNewChat = () => {
    if (session) fetchFeedbackStatus(session.id);
    setActivePage('chat');
    resetChatGuideData();
  };

  const handleToolbarClick = (newPage) => {
    setActivePage(newPage);
    posthog?.capture(`user_clicks_${newPage}_in_toolbar`);
    if (newPage === 'new_chat') handleNewChat();
  };

  useEffect(() => {
    if (feedbackStatus.loadingStatus !== LoadingStatus.LOADED) return;

    // TODO: Use an async function in handleBack to decouple feedback status and return to home behaviour
    if (!feedbackStatus.data.feedback_needed) retrieveSession(null);
  }, [feedbackStatus, retrieveSession]);

  const getPage = () => {
    if (activePage === 'about') return <AboutECoachCard />;
    if (activePage === 'history')
      return (
        <ECoachSessionList
          activeSession={session?.id}
          handleNewChat={handleNewChat}
          navigate={retrieveSession}
          resetChatGuideData={resetChatGuideData}
          setActivePage={setActivePage}
        />
      );
    return (
      <ECoachSession
        chatGuideData={chatGuideData}
        setChatGuideData={setChatGuideData}
      />
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        height: '100%',
      }}
    >
      <Toolbar
        activePage={activePage}
        handleToolbarClick={handleToolbarClick}
        chatGuideData={chatGuideData}
        session={session}
      />
      <FeedbackForm
        handleCreate={(data) => {
          tryCreate(session.id, data);
          retrieveSession(null);
        }}
        feedbackStatus={feedbackStatus}
      />
      {getPage()}
    </div>
  );
}

export default function ECoachPage() {
  return (
    <ErrorBoundary>
      <ECoachSessionProvider>
        <ECoach />
      </ECoachSessionProvider>
    </ErrorBoundary>
  );
}
