import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import ChatLanding from 'components/pageECoach/chatStart/ChatLanding';
import ConvoPrepForm from 'components/pageECoach/convoPrep/ConvoPrepForm';
import PromptHelper from 'components/pageECoach/promptHelper/_index';
import SampleCases from 'components/pageECoach/chatStart/SampleCases';
import { usePostHog, useFeatureFlagEnabled } from 'posthog-js/react';

// context & helpers
import {
  SessionProgressChoices,
  UserFeatureFlagType,
} from 'components/pageECoach/utils';
import { useSessionContext } from 'contexts/ECoachSessionContext';

export default function ChatStart({ chatGuideData, setChatGuideData }) {
  const { session, updateSession } = useSessionContext();
  const posthog = usePostHog();
  const { t } = useTranslation('pageEcoach');

  const isChallengeClarificationEnabled = useFeatureFlagEnabled(
    UserFeatureFlagType.ECOACH_CHALLENGE_CLARIFICATION
  );

  const openPromptHelper = () => {
    const updates = {
      displayConvoPrep: false,
      displayPolicyHelper: false,
      displayPromptHelper: true,
      displaySampleCases: false,
      chatStartEnded: false,
      inProgress: true,
    };

    setChatGuideData((prev) => ({ ...prev, ...updates }));
    posthog?.capture('user_clicks_prompt_helper');
  };

  const openConvoPrep = () => {
    const updates = {
      displayConvoPrep: true,
      displayPolicyHelper: false,
      displayPromptHelper: false,
      displaySampleCases: false,
      chatStartEnded: false,
      inProgress: true,
    };

    setChatGuideData((prev) => ({ ...prev, ...updates }));
    posthog?.capture('user_clicks_convo_prep_form');
  };

  const openSampleCases = () => {
    const updates = { displayPromptHelper: false, displaySampleCases: true };
    setChatGuideData((prev) => ({ ...prev, ...updates }));
  };

  const onChallengeSubmission = (userInput) => {
    const nextProgress = isChallengeClarificationEnabled
      ? SessionProgressChoices.CHALLENGE_VERIFICATION
      : SessionProgressChoices.SUGGESTIONS;

    updateSession(session?.id, {
      context: { description: userInput },
      progress: nextProgress,
    });
    posthog?.capture('user_submits_eCoach_challenge');

    const updates = {
      inProgress: false,
      chatStartEnded: true,
    };

    setChatGuideData((prev) => ({ ...prev, ...updates }));
  };

  const activatePolicyHelper = () => {
    onChallengeSubmission(t('I need information about my company policies'));
  };

  if (chatGuideData.displayConvoPrep)
    return (
      <ConvoPrepForm
        chatGuideData={chatGuideData}
        setChatGuideData={setChatGuideData}
      />
    );

  if (chatGuideData.displayPromptHelper)
    return (
      <PromptHelper
        chatGuideData={chatGuideData}
        onSubmit={onChallengeSubmission}
        setChatGuideData={setChatGuideData}
      />
    );

  if (chatGuideData.displaySampleCases)
    return (
      <SampleCases
        chatGuideData={chatGuideData}
        onSubmit={onChallengeSubmission}
      />
    );

  return (
    <>
      {!session && (
        <ChatLanding
          activatePolicyHelper={activatePolicyHelper}
          onSubmit={onChallengeSubmission}
          openConvoPrep={openConvoPrep}
          openPromptHelper={openPromptHelper}
          openSampleCases={openSampleCases}
        />
      )}
    </>
  );
}

ChatStart.propTypes = {
  chatGuideData: PropTypes.object,
  setChatGuideData: PropTypes.func,
};
