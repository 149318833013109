import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

// assets
import Fire from 'assets/nurauGraphics/fire.svg';
import MessageInfo from 'assets/nurauGraphics/message_info.svg';
import MessageQuestion from 'assets/nurauGraphics/message_question.svg';
import Notebook from 'assets/nurauGraphics/notebook.svg';
import PeopleJolt from 'assets/nurauGraphics/people_jolt.svg';

// components
import Chatbar from 'components/pageECoach/chatbar/Chatbar';
import PromptControl from 'components/pageECoach/chatStart/PromptControl';

// hooks
import { useUser } from 'contexts/UserContext';

// styles
import * as ChatStartStyle from 'components/pageECoach/_styles/ChatStart.style';

export default function ChatLanding({
  activatePolicyHelper,
  onSubmit,
  openConvoPrep,
  openPromptHelper,
  openSampleCases,
}) {
  const { t } = useTranslation('pageEcoach');
  const { data: user } = useUser();

  const emergencyLink = () => {
    const docPageURL = `/documents/195b172d-f368-4e53-83af-a634027ef450/pdf?language=${user.language}`;
    window.open(docPageURL, '_blank');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <ChatStartStyle.Section>
        <ChatStartStyle.Card>
          <ChatStartStyle.Label>
            {t('Choose or write your own below')}
          </ChatStartStyle.Label>
          <ChatStartStyle.PromptControls>
            <PromptControl
              action={openConvoPrep}
              icon={PeopleJolt}
              header="Conversation"
              description={t('Prepare for a conversation')}
            />
            <PromptControl
              action={activatePolicyHelper}
              icon={Notebook}
              header={t('Policies')}
              description={t('Get answers about policies')}
            />
            <PromptControl
              action={openPromptHelper}
              icon={MessageQuestion}
              header={t('Ideas')}
              description={t('Help me pick a problem')}
            />
            {user.organization.name === 'Ardene' && (
              <PromptControl
                action={emergencyLink}
                icon={Fire}
                header="Emergency"
                description={t('Click here in case of emergency')}
              />
            )}
            {user.is_demo && (
              <PromptControl
                action={openSampleCases}
                icon={MessageInfo}
                header="Samples"
                description={t('sampleCases.I want to try out some examples')}
              />
            )}
          </ChatStartStyle.PromptControls>
        </ChatStartStyle.Card>
      </ChatStartStyle.Section>
      <Chatbar autoFocus onSubmit={onSubmit} />
    </div>
  );
}

ChatLanding.propTypes = {
  activatePolicyHelper: PropTypes.func,
  onSubmit: PropTypes.func,
  openChatGuide: PropTypes.func,
  openConvoPrep: PropTypes.func,
  openPromptHelper: PropTypes.func,
  openSampleCases: PropTypes.func,
};
