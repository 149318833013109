import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// components
import ChatBubble from 'components/pageECoach/ChatBubble';
import PromptSetup from 'components/pageECoach/promptHelper/PromptSetup';
import QuestionGoal from 'components/pageECoach/promptHelper/QuestionGoal';
import QuestionNoTheme from 'components/pageECoach/promptHelper/QuestionNoTheme';
import QuestionTheme from 'components/pageECoach/promptHelper/QuestionTheme';

export default function PromptHelper(props) {
  const { chatGuideData, onSubmit, setChatGuideData } = props;
  const { t } = useTranslation('pageEcoach');

  const handleSelectedGoal = (selection) =>
    setChatGuideData((prev) => ({
      ...prev,
      promptHelper: { ...prev.promptHelper, goal: selection },
    }));

  const handleSelectedTheme = (selection) =>
    setChatGuideData((prev) => ({
      ...prev,
      promptHelper: { ...prev.promptHelper, theme: selection },
    }));

  const selectedTheme = chatGuideData.promptHelper.theme;
  const selectedGoal = chatGuideData.promptHelper.goal;

  return (
    <>
      <ChatBubble
        simpleString={t("I need advice on a challenge I'm currently facing.")}
        pointer={true}
        sentByUser
      />
      <QuestionTheme
        selectedTheme={chatGuideData.promptHelper.theme}
        handleSelectedTheme={handleSelectedTheme}
      />
      {selectedTheme && selectedTheme === 'Something else' && (
        <QuestionNoTheme chatGuideData={chatGuideData} onSubmit={onSubmit} />
      )}
      {selectedTheme && selectedTheme !== 'Something else' && (
        <QuestionGoal
          selectedGoal={selectedGoal}
          handleSelectedGoal={handleSelectedGoal}
          selectedTheme={selectedTheme}
        />
      )}
      {selectedTheme && selectedTheme !== 'Something else' && selectedGoal && (
        <PromptSetup chatGuideData={chatGuideData} onSubmit={onSubmit} />
      )}
    </>
  );
}

PromptHelper.propTypes = {
  chatGuideData: PropTypes.object,
  onSubmit: PropTypes.func,
  setChatGuideData: PropTypes.func,
};
