import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';

// components
import AboutCard from 'components/pageECoach/convoPrep/AboutCard';
import ChatBubble from 'components/pageECoach/ChatBubble';
import QuestionGoal from 'components/pageECoach/convoPrep/QuestionGoal';
import QuestionWho from 'components/pageECoach/convoPrep/QuestionWho';
import QuestionWorry from 'components/pageECoach/convoPrep/QuestionWorry';

// context & helpers
import { useSessionContext } from 'contexts/ECoachSessionContext';
import { SessionProgressChoices } from 'components/pageECoach/utils';

export default function ConvoPrepForm({ chatGuideData, setChatGuideData }) {
  const posthog = usePostHog();
  const { session, updateSession } = useSessionContext();
  const { t } = useTranslation('pageEcoach');

  const onSubmit = useCallback(() => {
    updateSession(session?.id, {
      context: { convo_prep: chatGuideData.convoPrep },
      progress: SessionProgressChoices.CONVERSATION_PLAN,
    });

    posthog?.capture('user_submits_convo_prep_form');

    const updates = {
      inProgress: false,
      chatStartEnded: true,
    };

    setChatGuideData((prev) => ({ ...prev, ...updates }));
  }, [
    chatGuideData.convoPrep,
    updateSession,
    posthog,
    session?.id,
    setChatGuideData,
  ]);

  useEffect(() => {
    if (chatGuideData.convoPrep.worried_by) onSubmit();
  }, [chatGuideData.convoPrep.worried_by, onSubmit]);

  return (
    <>
      <ChatBubble
        simpleString={t(
          'I need help preparing for a conversation with someone.'
        )}
        pointer={true}
        sentByUser
      />
      <AboutCard />
      <QuestionWho
        chatGuideData={chatGuideData}
        setChatGuideData={setChatGuideData}
      />
      {chatGuideData.convoPrep.with_who && (
        <QuestionGoal
          chatGuideData={chatGuideData}
          setChatGuideData={setChatGuideData}
        />
      )}
      {chatGuideData.convoPrep.goal && (
        <QuestionWorry
          chatGuideData={chatGuideData}
          setChatGuideData={setChatGuideData}
        />
      )}
    </>
  );
}

ConvoPrepForm.propTypes = {
  chatGuideData: PropTypes.object,
  setChatGuideData: PropTypes.func,
};
