import styled from 'styled-components';
import * as Cards from 'components/pageECoach/_styles/Cards.style';
import colours from 'constants/colours';
import device from 'constants/devices';
import * as Text from 'components/_styles/Text.style';
import YoungPassion from 'assets/young_passion.png';

export const Section = styled('div')`
  align-items: ${(props) => (props.eCoach ? 'flex-start' : 'flex-end')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    padding: 20px;
  }
`;

export const Card = styled(Cards.Card)`
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.4)
    ),
    url(${YoungPassion});
  background-position: center;
  background-size: cover;
  gap: 10px;
  max-width: 420px;
  padding: 20px;
`;

export const Label = styled(Text.P2Semibold)`
  color: ${colours.primary[900]};
`;

export const PromptControls = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const PromptControl = styled('div')`
  background-color: rgba(255, 255, 255, 70%);
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  padding: 20px;

  &:hover {
    background-color: ${colours.shade[0]};
  }
`;

export const Icon = styled('div')`
  align-items: center;
  background-color: ${colours.shade[0]};
  border-radius: 50px;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 5px;
  width: 40px;

  img {
    width: 100%;
  }

  svg path {
    stroke: ${colours.primary[700]};
  }
`;

export const TextSection = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Header = styled(Text.P3Semibold)`
  color: ${colours.primary[900]};
`;

export const Description = styled(Text.P2)`
  color: ${colours.primary[900]};
`;
